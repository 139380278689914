import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import OutboundLink from "../components/OutboundLink"
import Accordion from "../components/Accordion"
import OurMission from "../images/theme/img-about-mission.jpg"
import CVSHealthLogo from "../images/theme/logo-cvs-health-foundation.svg"
import TheCatchLogo from "../images/theme/logo-the-catch.svg"
import DiscoveryLogo from "../images/global/img-de-logo-TEMP.png"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Container>
        <Row>
          <Column span={12}>
            <h1>About Be Vape Free</h1>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  INTRO ***************/}
    <Section className="lightgray intro">
      <Container>
        <Row>
          <Column span={6}>
            <h2 className="serif">Our Mission</h2>
            <p>
              Brought to you by the CVS Health Foundation, CATCH Global
              Foundation, and Discovery Education, the Be Vape Free initiative
              aims to impact elementary, Middle, and High Schools nationwide to
              prevent the use of e-cigarettes by our nation’s youth. Through
              no-cost, standards-aligned resources for educators, self-paced
              modules for students, and a Parent and Community Tool Kit, we hope
              to create a united front and cultivate the first vape-free
              generation.
            </p>
          </Column>
          <Column span={6}>
            <img src={OurMission} alt="Our Mission" />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOUT BE VAPE FREE ***************/}
    <Section className="about-sponsors">
      <Container>
        <div id="about">
          <h2>About Be Vape Free</h2>
          <p>
            The <strong>Be Vape Free</strong> initiative is a confluence of
            like-minded organizations who have joined forces to tackle the use
            of e-cigarettes among our nation's youth through the creation and
            distribution of engaging content for grades 5–12 on the risks of
            e-cigarettes. By leveraging partner resources and working together
            as a collaborative initiative, we hope to keep students informed on
            the long-term health risks of vaping. Join our mission to provide
            educators and pharmacists with the tools to keep students informed
            in the classroom, and the evidence-based programs that will lead
            students to make healthy, smart decisions for life.
          </p>
        </div>
        <Row>
          <Column span={3}>
            <img
              src={CVSHealthLogo}
              alt="CVS Health"
              className="biolerplate__image link-external"
            />
          </Column>
          <Column span={9}>
            <h3>CVS Health Foundation</h3>
            <p>
              The CVS Health Foundation is a private charitable organization
              created by CVS Health that works to build healthier communities,
              enabling people of all ages to lead healthy, productive lives. The
              Foundation provides strategic investments to nonprofit partners
              throughout the U.S. who help increase community-based access to
              health care for underserved populations, create innovative
              approaches to chronic disease management and provide tobacco
              cessation and youth prevention programming. To learn more about
              the CVS Health Foundation and its giving, visit&nbsp;
              <OutboundLink to="https://www.cvshealth.com/social-responsibility">
                www.cvshealth.com/social-responsibility
              </OutboundLink>
            </p>
          </Column>
        </Row>
        <Row>
          <Column span={3}>
            <img
              src={TheCatchLogo}
              alt="The CATCH"
              className="biolerplate__image"
            />
          </Column>
          <Column span={9}>
            <h3>CATCH Global Foundation</h3>
            <p>
              CATCH Global Foundation is a 501(c)3 public charity founded in
              2014. Our mission is to improve children’s health worldwide by
              developing, disseminating and sustaining the CATCH platform in
              collaboration with researchers at UTHealth. The Foundation links
              underserved schools and communities to the resources necessary to
              create and sustain healthy change for future generations.
              <br />
              <OutboundLink to="https://www.catchinfo.org">
                www.catchinfo.org
              </OutboundLink>
            </p>
          </Column>
        </Row>
        <Row>
          <Column span={3}>
            <img
              src={DiscoveryLogo}
              alt="Discovery Education"
              className="biolerplate__image"
            />
          </Column>
          <Column span={9}>
            <h3>Discovery Education</h3>
            <p>
              Discovery Education is the worldwide edtech leader whose state-of-the-art digital platform supports learning wherever it takes place. Through its award-winning multimedia content, instructional supports, and innovative classroom tools, Discovery Education helps educators deliver equitable learning experiences engaging all students and supporting higher academic achievement on a global scale. Discovery Education serves approximately 4.5 million educators and 45 million students worldwide, and its resources are accessed in nearly 100 countries and territories. Inspired by the global media company Discovery, Inc., Discovery Education partners with districts, states, and trusted organizations to empower teachers with leading edtech solutions that support the success of all learners. Explore the future of education at&nbsp;
              <OutboundLink
                to="https://www.discoveryeducation.com"
                disableWarning={true}
              >
                DiscoveryEducation.com
              </OutboundLink>
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  PARTNERSHIPS ***************/}
    <Section className="toolkit-partnerships-container yellow-pattern">
      <Container>
        <Row>
          <Column span={10}>
            <h2>CVS Health Foundation Partnerships </h2>
            <p>
              In addition to <strong>Be Vape Free</strong>, the CVS Health
              Foundation is partnering with best-in-class organizations to
              cultivate the first vape-free generation. Explore these partners’
              engaging resources that help students quit smoking and vaping, and
              that help to ensure that those who haven’t started, never will.
            </p>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column span={8}>
            <Accordion
              title="Yale’s play2PREVENT Lab: smokeSCREEN Game"
              body={
                <p>
                  With a focus on behavior change, education, health,
                  well-being, and social intelligence, the smokeSCREEN Game
                  delivers an effective intervention to promote healthy lives
                  for youth and young adults.
                  <br />
                  <OutboundLink to="https://www.smokescreengame.org" withIcon>
                    smokeSCREENGame.org
                  </OutboundLink>
                </p>
              }
            />
            <Accordion
              title="Campaign for Tobacco-Free Kids: Taking Down Tobacco Online Advocacy Training"
              body={
                <p>
                  For more than 20 years, the Campaign for Tobacco-Free Kids has
                  fought to protect children and save lives from the #1 cause of
                  preventable death: tobacco use. These in-depth trainings help
                  young leaders take their advocacy to the next level.
                  <br />
                  <OutboundLink to="https://www.takingdowntobacco.org" withIcon>
                    www.takingdowntobacco.org
                  </OutboundLink>
                </p>
              }
            />
            <Accordion
              title="Truth Initiative: This is Quitting"
              body={
                <p>
                  Truth Initiative is America’s largest nonprofit public health
                  organization dedicated to making tobacco use a thing of the
                  past. Designed specifically for teens and young adults, This
                  is Quitting is a free quit-vaping text message program that is
                  one of the few resources available to young people.
                  <br />
                  <OutboundLink
                    to="https://www.truthinitiative.org/thisisquitting"
                    withIcon
                  >
                    www.truthinitiative.org
                  </OutboundLink>
                </p>
              }
            />
            <Accordion
              title="The American Lung Association: Communities in Action"
              body={
                <p>
                  The American Lung Association is leading the way in helping
                  all Americans breathe easier. We are America’s trusted source
                  for lung health education, lung disease research, support,
                  programs, services and advocacy. Access resources to help
                  create tobacco-free schools.
                  <br />
                  <OutboundLink to="https://www.lung.org/cvs" withIcon>
                    www.lung.org/cvs
                  </OutboundLink>
                </p>
              }
            />
            <Accordion
              title="DoSomething.org: The Hit We’ll Take Campaign"
              body={
                <p>
                  DoSomething.org is mobilizing young people in every US area
                  code to sign up for a volunteer, social change, or civic
                  action campaign to make real-world impact. The Hit We'll Take
                  Campaign empowers young people to join the movement to make
                  the next generation tobacco and vape-free. <br />
                  <OutboundLink
                    to="https://www.dosomething.org/us/campaigns/the-hit-well-take"
                    withIcon
                  >
                    www.dosomething.org
                  </OutboundLink>
                </p>
              }
            />
            <Accordion
              title="American Cancer Society: Tobacco-Free Generation Campuses Initiative"
              body={
                <p>
                  The Tobacco-Free Generation Campus Initiative provides
                  generous grants to accelerate and expand the adoption and
                  implementation of 100% smoke- and tobacco-free policies on
                  college campuses across the nation. <br />
                  <OutboundLink to="https://www.tobaccofreecampus.org" withIcon>
                    www.tobaccofreecampus.org
                  </OutboundLink>
                </p>
              }
            />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
)
export default AboutPage
